const encode = (plain) => JSON.stringify({ObjectCastContainer: plain})

const decode = (value, defaultValue) => {
  return typeof value === "string"
    ? JSON.parse(value).ObjectCastContainer
    : defaultValue
}

export default {
  get(key, defaultValue = null) {
    return decode(localStorage.getItem(key), defaultValue)
  },
  getAll() {
    const data = {}
    const length = localStorage.length
    for (let i = 0; i < length; i++) {
      const key = localStorage.key(i)
      data[key] = this.get(key)
    }
    return data
  },
  getToken() {
    return this.getUser()?.token
  },
  getUser() {
    return this.get("user")
  },
  getMultiple(keys, defaultValues = []) {
    return Object.fromEntries(keys.map((key, i) => {
      const defaultValue = defaultValues && i < defaultValues.length ? defaultValues[i] : null
      return [key, this.get(key, defaultValue)]
    }))
  },
  set(key, value) {
    return localStorage.setItem(key, encode(value))
  },
  setToken(token) {
    const user = this.getUser() || {}
    user.token = token
    return this.setUser(user)
  },
  setUser(user) {
    return this.set("user", user)
  },
  setMultiple(data) {
    Object.entries(data).forEach(([key, value]) => this.set(key, value))
  },
  remove(key) {
    return localStorage.removeItem(key)
  },
  clear() {
    return localStorage.clear()
  },
}
