import Storage from "@/helpers/Storage"
import {fullName} from "@/mixins/TemplateUtils"

export const DefaultRole = "ROLE_USER"

export const Role = {
  ADMIN: "ROLE_SUPER_ADMIN",
  FUNC_ADMIN: "ROLE_FUNCTIONAL_ADMIN",
  TECH_ADMIN: "ROLE_TECHNICAL_ADMIN",
  HR: "ROLE_RH",
  APPROVER: "APPROVER",
  EXECUTOR: "EXECUTOR",
  CASHIER: "CASHIER",
  ACCOUNTANT: "ACCOUNTANT",
}

export default {
  user() {
    return Storage.getUser()
  },
  name() {
    return fullName(this.user()) || null
  },
  isAuthenticated() {
    const user = this.user()
    if (user) {
      if (user.token/* && new Date(user.tokenExpireAt) > new Date()*/)
        return true

      Storage.clear()
    }
    return false
  },
  hasRole(role) {
    if (!role)
      return true

    const roles = this.user()?.roles

    if (role?.constructor === Array) {
      if (role.length === 1)
        role = role[0]
      else
        return roles?.some(r => role.includes(r))
    }

    return roles?.some(r => r === role)
  },
  isAdmin() {
    return this.hasRole(Role.ADMIN)
  },
  isFuncAdmin() {
    return this.hasRole(Role.FUNC_ADMIN)
  },
  isTechAdmin() {
    return this.hasRole(Role.TECH_ADMIN)
  },
  isFuncOrTechAdmin() {
    return this.isFuncAdmin() || this.isTechAdmin()
  },
  isAnyAdmin() {
    return this.isFuncAdmin() || this.isTechAdmin() || this.isAdmin()
  },
  isHR() {
    return this.hasRole(Role.HR)
  },
  hasRight(right, caisse) {
    const res = this.hasRole(right)

    if (caisse) {
      const rights = this.user()?.userCashBoxRights
      if (rights?.constructor === Array)
        return res && rights.some(r => r.allowedRight.slug === right && r.cashBox.id === caisse.id)
      else
        return false
    }

    return res
  },
  getCaisses(right) {
    const rights = right.constructor === Array ? right : [right]
    return this.user()?.userCashBoxRights
      ?.filter(r => rights.includes(r.allowedRight.slug))
      ?.map(r => r.cashBox) || []
  },
  isCaisseMember(caisse = null) {
    const res = this.hasRole([Role.APPROVER, Role.EXECUTOR, Role.CASHIER, Role.ACCOUNTANT])

    if (caisse) {
      const rights = this.user()?.userCashBoxRights
      if (rights?.constructor === Array)
        return res && rights.some(r => r.cashBox.id === caisse.id)
      else
        return false
    }

    return res
  },
  isApprover(caisse = null) {
    return this.hasRight(Role.APPROVER, caisse)
  },
  isExecutor(caisse = null) {
    return this.hasRight(Role.EXECUTOR, caisse)
  },
  isCashier(caisse = null) {
    return this.hasRight(Role.CASHIER, caisse)
  },
  isAccountant(caisse = null) {
    return this.hasRight(Role.ACCOUNTANT, caisse)
  },
}
